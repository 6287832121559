import { RowItem } from "@/types/common.types";

export interface CommonResponse<T> {
  statusCode: number;
  error: string;
  message: string;
  data: T;
}
export interface zodiacDataType {
  logo: string;
  name: string;
  value: ZodiacTypes;
}

export interface LogoNavBarDataType {
  logo?: string;
  name: string;
  color?: string;
}

export interface timingsDataType {
  name: string;
  initial_time: string;
  final_time: string;
}

export interface MiniCardDetailsType {
  image: string;
  names: string[];
}

export interface AstroCardDataType {
  image: string;
  name: string;
  diamonds: number;
  id?: string;
}

export interface AstroLiveCardDataType extends RoomResponse {
  photo: string;
  years_of_experience: number;
  live: boolean;
  language: string[];
  name: string;
  topic: string[];
  image: string;
}

export interface KundliCardsDataType {
  backImage: string;
  icon: string;
  name: string;
  page: string;
}

export interface FormInitialDataType {
  name?: string;
  DOB?: string;
  time_of_birth?: string;
  place_of_birth?: string;
}

export interface genderType {
  male: string;
  female: string;
}

export interface colorCardType {
  icon?: string;
  title: string;
  description: string;
  key?: string;
  received_points?: number;
  total_points?: number;
  bgColor?: string;
}

export interface manglikDataType {
  name: string;
  type: string;
}

export interface kundliDataType {
  id: number;
  name: string;
  DOB: string;
  time_of_birth: string;
  location: string;
}

export interface AstroPredictionReq {
  token?: string;
  contextId?: string;
  type?: AstroPredictionType;
  zodiacName: ZodiacTypes;
}
export interface LiveRoomData {
  id: number;
  name: string;
  privacy: string;
  userId: string;
  shareableUrl: string;
  roomIcon: string;
  playUrl: string;
  membersCount: number;
  isCreator: boolean;
  languages: string[];
}

export interface UserProfileData {
  userId: string;
  userName: string;
  rate: number;
  name: string;
  country: string;
  status: string;
  level: number;
  photo: string;
}

export interface RoomResponse {
  liveRoom: LiveRoomData;
  userProfile: UserProfileData;
}

export interface SubCategoryData {
  _id?: string;
  name: string;
  icon?: string;
  viewType?: string;
  title?: string;
}

export interface CategoryData {
  _id: string;
  name: string;
  icon: string;
  viewType: string;
  filterAllowed: boolean;
  title: string;
  subCategories: SubCategoryData[];
}

export interface RoomsPageData {
  data: RoomResponse[];
  user?: UserData;
  error?: ErrorType["code"];
  categories: CategoryData[];
  nonAuthToken?: string;
}

export interface UserData {
  _id: string;
  name: string;
  username: string;
  isAdult: boolean;
  profilePic: string;
  status: string;
  isRegistered: boolean;
  token: string;
  isAuthenticated: boolean;
}

export interface ErrorType {
  code: number;
  message: string;
}

export enum ROOM_TYPE {
  AUDIO = "audio",
  LIVE = "live",
  CALL = "call",
  CHAT = "chat",
}

export interface RoomReqBody {
  start: number;
  size: number;
  roomType: ROOM_TYPE;
  location?: number[];
  category?: string;
  subCategory?: string;
}

export interface AstroUserDetailsReq {
  token?: string;
  contextId?: string;
  name?: string;
  date: string;
  lat: string;
  long: string;
  tzone: string;
  gender?: string;
}

export interface GeoLocation {
  place_name: string;
  latitude: string;
  longitude: string;
  timezone_id: string;
  country_code: string;
  timezone?: string;
}

export interface GeoLocationRes {
  geonames: GeoLocation[];
}

export interface AstroSunSignResponse {
  status: boolean;
  sun_sign: string;
  prediction_date: string;
  prediction: {
    [key: string]: string;
  };
}

export interface AstroHoroscopeResponse {
  status: boolean;
  sun_sign: string;
  prediction_month: string;
  prediction: string[];
}

export interface PanchangDetails {
  name: string;
  value: string;
  startTime?: string;
  endTime?: string;
}

export interface AstroSunDetails {
  sunrise: string;
  sunset: string;
  moonrise: string;
  moonset: string;
}

export interface AstroDailyPanchangData {
  day: string;
  sunDetails: AstroSunDetails;
  panchangDetails: PanchangDetails[];
  additionalDetails: PanchangDetails[];
  inAuspiciousTime: PanchangDetails[];
  auspiciousTime: PanchangDetails[];
}

export interface AstroUserData {
  date: string;
  lat: string;
  long: string;
  tzone: string;
  name: string;
  dob: string;
  tob: string;
  pob: string;
  gender: string;
  zodiac: string;
  city: string;
}

export interface ZodiacCompatibilityType {
  zodiac: string;
  partnerZodiac: string;
}

export interface walletBalanceDataType {
  diamonds: number;
  beans: number;
  videoCards: number;
  astroVCard: number;
}

export interface AstroUserDataReq {
  maleData?: AstroUserData;
  femaleData?: AstroUserData;
}

export enum AstroPredictionType {
  PREVIOUS = "previous",
  DAILY = "daily",
  NEXT = "next",
}

export enum ZodiacTypes {
  ARIES = "aries",
  TAURUS = "taurus",
  GEMINI = "gemini",
  CANCER = "cancer",
  LEO = "leo",
  VIRGO = "virgo",
  LIBRA = "libra",
  SCORPIO = "scorpio",
  SAGITTARIUS = "sagittarius",
  CAPRICORN = "capricorn",
  AQUARIUS = "aquarius",
  PISCES = "pisces",
}

// kundli match and manglik report
export interface KundliMatchItem {
  description: string;
  total_points: number;
  received_points: number;
  status?: boolean;
  report?: string;
}

export interface AstroKundliMatchResponse {
  [key: string]: KundliMatchItem;
  total: KundliMatchItem;
  conclusion: KundliMatchItem;
}

export interface AstroManglikUserReport {
  is_present: boolean;
}

export interface AstroManglikResponse {
  male: AstroManglikUserReport;
  female: AstroManglikUserReport;
  conclusion: {
    match: boolean;
    report: string;
  };
}

export interface UserAstroProfile {
  id: number;
  userId: string;
  type: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  tob: string;
  pob: string;
  maritalStatus: string;
  occupation: string;
  toc: string;
  createdAt: string;
  updatedAt: string;
}

export interface ObjectType {
  [key: string]: string;
}

export interface FreeKundliAshtakvargaItem {
  sun: number;
  moon: number;
  mars: number;
  mercury: number;
  jupiter: number;
  venus: number;
  saturn: number;
  ascendant: number;
  total: number;
}

export interface AstroFreeKundliBasicResponse {
  basic: ObjectType;
  panchang: ObjectType;
  avakadha: ObjectType;
}

export interface AstroFreeKundliAshtakvargaResponse {
  ashtak_points: {
    [key: string]: FreeKundliAshtakvargaItem;
  };
}

export interface AstroFreeKundliPlanetResponse {
  sign: {
    planet: string;
    sign: string;
    signLord: string;
    degree: number;
    house: number;
  }[];
  nakshatra: {
    name: string;
    nakshatra: string;
    nakshatraLord: string;
    house: number;
  }[];
}

export interface AstroPanchangChoghadiyaResponse {
  day: {
    time: string;
    muhurta: string;
    hora: string;
  }[];
  night: {
    time: string;
    muhurta: string;
    hora: string;
  }[];
}

export interface ZodiacCompatibilityRes {
  your_sign: string;
  your_partner_sign: string;
  compatibility_report: string;
  compatibility_percentage: number;
}

export interface AstroFreeKundliKpResponse {
  chartImg: string;
  kpData: {
    planets: {
      planet_name: string;
      sign_name: string;
      house: number;
      sign_lord: string;
      nakshatra_lord: string;
      sub_lord: string;
    }[];
    houses: {
      sign_name: string;
      sign_lord: string;
      full_degree: number;
      nakshatra_lord: string;
      sub_lord: string;
    }[];
  };
}

export interface AstroFreeKundliDashaResponse {
  vimshottari: AstroDashaType[];
  yogini: AstroDashaType[];
}

export interface AstroFreeKundliReportResponse {
  dasha: {
    name: string;
    description: string;
    isPresent: boolean;
  }[];
  general: {
    [key: string]: string[];
  };
  houses: {
    house: number;
    sign: string;
    degree: number;
    report: string;
  }[];
}
export interface AstroDashaType {
  Planet: string;
  "Start Date": string;
  "End Date": string;
}

export interface AstroBasicData {
  basic: RowItem[];
  panchang: RowItem[];
  avakadha: RowItem[];
}

export interface AstroPlanetData {
  sign: RowItem[];
  nakshatra: RowItem[];
}

export interface AstroChartData {
  North?: ObjectType;
  South?: ObjectType;
}

export interface AstroKpData {
  chartImg: string;
  kpData: {
    planets: RowItem[];
    cusps: RowItem[];
  };
}

export interface FreeKundliData {
  Basics?: AstroBasicData;
  Planets?: AstroPlanetData;
  Charts?: AstroChartData;
  KP?: AstroKpData;
  Asktakvarga?: RowItem[];
  Dasha?: {
    vimshottari: RowItem[];
    yogini: RowItem[];
  };
  Report?: AstroFreeKundliReportResponse;
}

export interface TableDataType {
  [key: string]: string | number;
}

export type TabOption = {
  label: string;
  value: string;
};

export interface AstroContainerProps {
  liveAstrologers?: RoomResponse[];
  callAstrologers?: RoomResponse[];
}

export interface AstroDashaReport {
  name: string;
  isPresent: boolean;
  shortDesc: string;
  description: string;
  title?: string;
}

export interface DashaPeriodType {
  planet: string;
  planet_text: string;
  planet_id: number;
  start: string;
  end: string;
}

export interface DashaPeriod {
  dasha_period: DashaPeriodType[];
}

export interface AstroVimshottariReport {
  major: DashaPeriod;
  minor: DashaPeriod;
}

export interface GemstoneReport {
  LIFE: {
    [key: string]: string;
  };
  BENEFIC: {
    [key: string]: string;
  };
  LUCKY: {
    [key: string]: string;
  };
}

export interface PoojaReport {
  summary: string;
  suggestions: {
    status: boolean;
    priority: number;
    title: string;
    puja_id: string;
    summary: string;
    one_line: string;
  }[];
}

export interface ChineseHoroScopeReport {
  chinese_zodiac: string;
  forecast_year: number;
  forecast: string[];
}
